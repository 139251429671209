export const postToUrl = (url, data) => {
  // Create a dummy form.
  const postForm = document.createElement('form');
  postForm.method = 'POST';
  postForm.action = url;

  // Save the submit function in case it gets overridden by
  // an input with the name "submit"
  const submitFn = postForm.submit;

  // Create an array of inputs based on the data we've passed.
  const inputs = generateFormInputs(data);
  if (hasFileInput(inputs)) {
    postForm.enctype = 'multipart/form-data';
  }

  // Add the inputs to the form.
  inputs.map(input => postForm.appendChild(input));

  // Submit and remove the form.
  document.body.appendChild(postForm);

  // Call the submit function on our form.
  submitFn.call(postForm);

  postForm.parentNode.removeChild(postForm);
};

export const animationFrame = () => new Promise(res => {
  requestAnimationFrame(res);
});

/**
 * Generates an array of inputs suitable for appending to a form object.<br/>
 * Recursively calls itself to create arrays of inputs for nested objects.
 * @param {object} data The data object to create inputs for.
 * @returns {Array} An array of HtmlInputElements.
 */
function generateFormInputs(data) {
  // Using an anonymous function to obfuscate the outer_objects param.
  const _theFunc = function (data, outer_objects) {
    let inputs = [];

    for (const x in data) {
      if (!data.hasOwnProperty(x)) {
        continue;
      }

      const value = data[ x ];

      if (value instanceof HTMLInputElement) {
        if (value.type === 'file') {
          // This is a file input. Clone it and add to our return.
          const cloned = value.cloneNode();
          cloned.files = value.files;

          inputs.push(cloned);

          continue;
        }
      }

      if (typeof value === "object") {
        // We need to use a tmp variable, otherwise the outer_objects
        // variable
        // will be used even when we're not in a recursive call of this
        // function.
        let tmp_outer_objects;
        if (typeof outer_objects !== "string") {
          tmp_outer_objects = x;
        }else {
          tmp_outer_objects = outer_objects + "[" + x + "]";
        }
        inputs = inputs.concat(_theFunc(value, tmp_outer_objects));
      }else {
        // Create the name of the input based on the outer_objects str we've
        // been appending to.
        let name = x;
        if (typeof outer_objects === "string") {
          name = outer_objects + "[" + x + "]";
        }

        // Push the new input into our inputs array.
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;

        inputs.push(input);
      }
    }

    return inputs;
  };

  return _theFunc(data);
}

function hasFileInput(inputs) {
  return inputs.some(input => input.type === 'file');
}
