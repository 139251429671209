export const isMetamaskInstalled = () => {
  return window.ethereum && window.ethereum.isMetaMask;
}

// create a signed signature to log in with
export const signMessage = async (message) => {
  if (!window.ethereum) {
    console.error('No ethereum object on window');
    return;
  }

  console.log("Signing with account: ", window.ethereum.selectedAddress);

  return await window.ethereum.request({
    method: 'personal_sign',
    params: [message, window.ethereum.selectedAddress]
  });
}

export const verifySignature = async (message, signature) => {
  if (!window.ethereum) {
    console.error('No ethereum object on window');
    return;
  }

  return await window.ethereum.request({
    method: 'personal_ecRecover',
    params: [message, signature]
  });
}

export const getUserAccounts = async () => {
  if (!window.ethereum) {
    console.error('No ethereum object on window');
    return;
  }

  return await window.ethereum.request({method: 'eth_requestAccounts'});
};

export const getBalance = async (address) => {
  const balance = await window.ethereum.request({
    method: 'eth_getBalance',
    params: [address, 'latest']
  });

  return parseInt(balance, 16);
};
