import 'regenerator-runtime/runtime';
import {html, LitElement} from 'lit';
import '@polymer/app-route/app-location';
import '@polymer/app-route/app-route';
import '@polymer/iron-image/iron-image.js';
import '@polymer/paper-spinner/paper-spinner';
import {LitLocalize} from "@cbar/lit-localize";
import {
  getUserAccounts,
  isMetamaskInstalled,
  signMessage
} from "../scripts/custom/es6/metamask";
import {postToUrl} from "../scripts/custom/es6/utility";

class AuthentickatedLogin extends LitLocalize(LitElement) {
  static get properties() {
    return {
      authentickatedProviderName: {
        type: String,
      },
      socialProviders: {
        type: Array
      },
      clientId: {
        type: String,
      },
      redirectUri: {
        type: String,
      },
      responseType: {
        type: String,
      },
      scope: {
        type: String,
      },
      state: {
        type: String,
      },
      nonce: {
        type: String,
      },
      /**
       * A token unique to the current login form. Session data is saved
       * against this token and retrieved once the form is POSTed
       */
      formToken: {
        type: String,
      },
      logo: {
        type: String,
      },
      directLogin: {
        type: Boolean,
      },
      selectedPage: {
        type: String,
      },
      signupEmail: {
        type: String,
      },
      loading: {
        type: Boolean,
      },
      registerProcessing: {
        type: Boolean,
      },
      terms: {
        type: String
      },
      serviceUrl : {
        type: String
      },
      successUrl : {
        type: String
      }
    };
  }

  static get is() {
    return 'authentickated-login';
  }

  constructor() {
    super();

    this.loading = false;
    this.authentickatedProviderName = 'Authentickated';
    this.registerProcessing = false;
    this.responseType = 'code';
    this.directLogin = false;

    this.clientId = '';
    this.redirectUri = '';
    this.responseType = '';
    this.scope = '';
    this.state = '';
    this.nonce = '';
    this.formToken = '';
    this.logo = '';
    this.signupEmail = '';
    this.terms = '';
    this.serviceUrl = '';
    this.successUrl = '';
  }

  render() {
    return html`
      <style>
        authentickated-login {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          padding-top: 56px;
          font-family: Roboto, noto, sans-serif;
        }

        .secured {
          margin-top: 24px;
          margin-bottom: 16px;
          color: #8e8e8e;
          text-align: center;
        }
        
        .secured:first-letter {
          text-transform: uppercase;
        }

        .image-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }

        iron-image {
          max-width: 200px;
          --iron-image-width: 100%;
        }

        paper-spinner[hidden] {
          display: none;
        }
      </style>

      <app-location id="location" @query-params-changed="${this.onQueryParamsChanged}"></app-location>
      <paper-spinner hidden=${!this.loading} active></paper-spinner>

      <iron-pages attr-for-selected="name" selected="${this.selectedPage}">
        <cbar-login
          name="login"
          .directLogin="${this.directLogin}"
          cbar-base-url="${window.origin}"
          client-id="${this.clientId}"
          social-providers="${JSON.stringify(this.socialProviders)}"
          @social-button-tap="${this.onSocialButtonTapped}"
          redirect-uri="${this.redirectUri}"
          state="${this.state}"
          response-type="${this.responseType}"
          scope="${this.scope}"
          nonce="${this.nonce}"
          form-token="${this.formToken}"
          logo="${this.logo}"
          allow-register
          @register-clicked="${this.onRegisterClicked}"
          service-url="${this.serviceUrl}"
        ></cbar-login>

        <cbar-register
          name="register"
          redirect-url="${this.redirectUri}"
          .processing="${this.registerProcessing}"
          tc-link="javascript:window.open('https://www.communitybuildar.com/terms/autp')"
          client-tc-link="${this.terms}"
          logo="${this.logo}"
          show-login
          @processing-changed="${this.onRegisterProcessingChanged}"
          @email-changed="${this.onEmailChanged}"
          @login-clicked="${this.onLoginClicked}"
          success-url="${this.successUrl}"
          @register-submitted="${this.onRegisterSubmitted}"
        ></cbar-register>
      </iron-pages>
      
    ${!this.loading ? html`
        <div class="secured">${this.localize('securedBy')}</div>
        <div class="image-wrapper">
            <iron-image src="https://login.authentickated.com/resources/image/42.png"></iron-image>
        </div>
    ` : ``}
    `;
  }

  createRenderRoot() {
    // No shadow root for password manager support
    return this;
  }

  get selectedPage() {
    return this._selectedPage;
  }

  get loading(){
    return this._loading;
  }

  set loading(loading){
    this._loading = loading;
    this.requestUpdate();
  }

  set selectedPage(page) {
    this._selectedPage = page;

    this.loading = true;

    if (page === 'register') {
      import('@cbar/cbar-register').then(_ => this.loading = false);
    }else {
      import('@cbar/cbar-login').then(_ => this.loading = false);
    }
  }

  onRegisterProcessingChanged(e) {
    this.registerProcessing = e.detail.value;
  }

  onQueryParamsChanged(e) {
    const queryParams = e.detail.value;

    this.selectedPage = queryParams.register === '1' ? 'register' : 'login';
  }

  onEmailChanged(e) {
    this.signupEmail = e.detail.value;
  }

  onLoginClicked() {
    this.selectedPage = 'login';
  }

  onRegisterClicked() {
    this.selectedPage = 'register';
  }
  
  onRegisterSubmitted(e) {
    const userDetails = e.detail;

    const params = {
      first_name: userDetails.firstName,
      last_name: userDetails.lastName,
      email: userDetails.email,
      dob_d: userDetails.dob.day,
      dob_m: userDetails.dob.month,
      dob_y: userDetails.dob.year,
      mobile: userDetails.mobile.country ?? '',
      client_id: this.clientId,
      redirect_uri : this.redirectUri,
      form_token: this.formToken,
      success_url : this.successUrl,
      submit: "create profile",
      return_json: true
    };

    const body = this._serializeParams(params);

    fetch('/user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body,
    })
      .then(response => response.json())
      .then((e) => this.onRegistrationSuccess(e))
      .catch((e) => this.onRegistrationError(e));
  }

  onRegistrationSuccess(e) {
    let eventReturn = e;
    let urlParams = new URLSearchParams(window.location.search);

    let params = {
      oauth_redirect: 1,
      redirect_uri: urlParams.get('redirect_uri'),
      client_id: urlParams.get('client_id'),
      state: urlParams.get('state'),
      response_type: urlParams.get('response_type'),
      scope: urlParams.get('scope'),
      registered: true,
      email: this.signupEmail,
    };

    // we have to get some form of redirect here to check where this should be sending us on success
    window.location.href = encodeURI(`${eventReturn.redirect ? eventReturn.redirect : this.redirectUri}?${this._serializeParams(params)}`);
  }

  onRegistrationError(e) {
    this.registerProcessing = false;

    console.warn('Error while registering: ', e);
  }

  async onSocialButtonTapped(e) {
    const tappedButton = e.detail.button;
    const provider = tappedButton.provider;

    if (provider === 'metamask') {
      e.preventDefault();

      if (!isMetamaskInstalled()) {
        alert('Please install MetaMask to continue');
      }

      const accounts = await getUserAccounts();
      const account = accounts[0];

      const message = `${this.authentickatedProviderName} wants you to sign in with your MetaMask account.\n\nPlease sign this message to confirm your identity.`;
      const signature = await signMessage(message);

      postToUrl('/metamask/login', {
        message,
        signature,
        account,
      });
    }
  }

  _serializeParams(params) {
    return Object.keys(params).map(k => {
        let value = params[ k ];
        if (value) {
          value = encodeURIComponent(value);
        }

        return `${encodeURIComponent(k)}=${value}`
      }
    ).join('&');
  }
}

window.customElements.define(AuthentickatedLogin.is, AuthentickatedLogin);
